import React from "react"
import { graphql } from "gatsby"
import { Layout, Posts, SEO } from "../components"

const PostsPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <SEO title="投稿一覧" />
      <Posts posts={posts} title="投稿一覧" />
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          date(formatString: "MMM Do,YYYY ")
          slug
          readTime
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
        body
      }
    }
  }
`

export default PostsPage
